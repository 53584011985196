import React, { useState, useEffect } from 'react'
import Table from 'react-bootstrap/Table'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import ListGroup from 'react-bootstrap/ListGroup'
import Card from 'react-bootstrap/Card'
import * as Yup from 'yup'
import Loader from '../../../components/Loader'
import Select from 'react-select'
import { Formik, Form, ErrorMessage } from 'formik'
import { Form as frm } from 'react-bootstrap'
import CustomToast from '../../../components/Toast/CustomToast'
import axiosProvider from '../../../lib/AxiosProvider'
import CustomScrollSpy from '../../../components/CustomScrollSpy'
import { useDispatch } from 'react-redux'
import { setPageTitle } from '../../redux/slice/pageTitleSlice'
import {
  _adharBackImg_,
  _adharFrontImg_,
  _brandCertificateImg_,
  _brandImg_,
  _cancelCheaque_,
  _digitalSignImg_,
  _kycImg_,
  _msmeImg_,
  _panCardImg_
} from '../../../lib/ImagePath'
import { _gstInfoImg_ } from '../../../lib/ImagePath'
import { _alphabetRegex_ } from '../../../lib/Regex'
import TextError from '../../../components/TextError'
import { customStyles } from '../../../components/customStyles'
import FileSaver, { saveAs } from 'file-saver'

function SellerDetails() {
  const { id } = useParams()
  const [editData, setEditData] = useState()
  const [loading, setLoading] = useState(false)
  const [toast, setToast] = useState({
    show: false,
    text: null,
    variation: null
  })
  const navigate = useNavigate()

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setPageTitle('Seller Details'))
  }, [])

  useEffect(() => {
    if (id) {
      fetchData(id)
    }
  }, [id])

  const fetchData = async (id) => {
    setLoading(true)
    const response = await axiosProvider({
      method: 'GET',
      endpoint: `SellerData/Details?id=${id}`
    })
      .then((res) => {
        if (res?.status === 200) {
          setEditData(res?.data)
        }
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        alert(err)
      })
  }

  const downloadURI = async (uri, name, folderName) => {
    let staticLink = encodeURI(
      `${process.env.REACT_APP_IMG_URL}${folderName}${uri}`
    )

    try {
      FileSaver.saveAs(staticLink)
    } catch (error) {}
  }

  const onSubmit = async (values, { resetForm }) => {
    setLoading(true)
    const response = await axiosProvider({
      method: 'PUT',
      endpoint: `seller/KYC/UpdateSellerKyc`,
      data: { ...values, sellerId: editData?.userID }
    })
      .then((res) => {
        setLoading(false)
        if (res?.status === 200) {
          setToast({
            show: true,
            text: res?.data?.message,
            variation: res?.data?.code !== 200 ? 'error' : 'success'
          })

          setTimeout(() => {
            navigate('/manage-seller')
            setToast({ ...toast, show: false })
          }, 2000)
        } else {
          setToast({
            show: true,
            text: res?.data?.message,
            variation: res?.data?.code !== 200 ? 'error' : 'success'
          })

          setTimeout(() => {
            setToast({ ...toast, show: false })
          }, 2000)
        }
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const validationSchema = Yup.object().shape({
    note: Yup.string().matches(_alphabetRegex_, 'Please enter alphabet only'),
    status: Yup.string().required('Please select status')
  })

  return (
    <div className='pv-seller-detail-main row w-100 m-auto'>
      {loading && <Loader />}
      {toast?.show && (
        <CustomToast text={toast?.text} variation={toast?.variation} />
      )}
      <div className='col-md-3 pv-seller-detail-col ps-0'>
        <CustomScrollSpy
          targetIds={
            editData?.brands?.length > 0
              ? [
                  'Basic-Info',
                  'Personal-Info',
                  // 'Finanace-Info',
                  // 'Kyc-Info',
                  // 'GST-Info',
                  // 'Warehouse-Info',
                  'Brand-List'
                  // 'Download-Documents'
                ]
              : [
                  'Basic-Info',
                  'Personal-Info'
                  // 'Finanace-Info',
                  // 'Kyc-Info',
                  // 'GST-Info',
                  // 'Warehouse-Info',
                  // 'Brand-List',
                  // 'Download-Documents'
                ]
          }
        >
          {' '}
        </CustomScrollSpy>
      </div>
      <div className='col-md-9 pv-seller-detail-col'>
        <div
          className=' pv-basicinfo-main pv-basicinfo-maintext rounded align-items-center justify-content-between'
          id='Basic-Info'
        >
          <div className='row'>
            <span className='pv-seller-detail-head mb-2'>Basic Info</span>
            <div className='col-md-3'>
              <div className='pv-basic-img d-flex justify-content-center'>
                <img
                  height='150px'
                  width='150px'
                  src={`${process.env.REACT_APP_IMG_URL}${_brandImg_}${editData?.brands[0]?.logo}`}
                />
              </div>
            </div>
            <div className='col-md-9'>
              <div className='row gap-4'>
                <div className='col'>
                  <div>
                    <Table>
                      <tbody>
                        <tr>
                          <th>Display Name&nbsp;:-</th>

                          <td>{editData?.displayName}</td>
                        </tr>
                        <tr>
                          <th>Full Name&nbsp;:-</th>
                          <td> {editData?.fullName} </td>
                        </tr>
                        <tr>
                          <th>Owner Name&nbsp;:-</th>
                          <td> {editData?.ownerName} </td>
                        </tr>
                        <tr>
                          <th>Business Type&nbsp;:-</th>
                          <td> {editData?.bussinessType} </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
                <div className='col'>
                  <div>
                    <Table>
                      <tbody>
                        <tr>
                          <th>User Name&nbsp;:-</th>

                          <td>{editData?.userName}</td>
                        </tr>
                        <tr>
                          <th>Company Type&nbsp;:-</th>
                          <td>{editData?.typeOfCompany}</td>
                        </tr>
                        <tr>
                          <th>Company Reg No&nbsp;:-</th>
                          <td>{editData?.companyRegitrationNo}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div className=' pv-basicinfo-maintext' id='Personal-Info'>
          <div className='row'>
            <div className='col'>
              <div className='pv-basicinfo-main rounded'>
                <span className='pv-seller-detail-head'>Personal Info</span>
                <Table>
                  <tbody>
                    <tr>
                      <th>Name&nbsp;:-</th>
                      <td>{editData?.fullName}</td>
                    </tr>
                    <tr>
                      <th>Email ID&nbsp;:-</th>
                      <td>{editData?.userName}</td>
                    </tr>
                    <tr>
                      <th>Phone No&nbsp;:-</th>
                      <td>{editData?.phoneNumber}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
            <div className='col d-flex' id='Contact-Person'>
              <div className='w-100 pv-basicinfo-main pv-basicinfo-maintext rounded'>
                <span className='pv-seller-detail-head'>Contact Person</span>

                <Table>
                  <tbody>
                    <tr>
                      <th>Name&nbsp;:-</th>

                      <td>{editData?.contactPersonName}</td>
                    </tr>
                    <tr>
                      <th>Phone No&nbsp;:-</th>
                      <td>{editData?.contactPersonMobileNo}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
            {editData?.note && (
              <div className='col-12'>
                <div className='pv-basicinfo-main rounded'>
                  <span className='pv-seller-detail-head'>Note</span>
                  <p>{editData?.note}</p>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* <div className='pv-finanace-info'>
          <div className='row'>
            <div className='col pv-basicinfo-maintext' id='Finanace-Info'>
              <div className='pv-basicinfo-main rounded'>
                <span className='pv-seller-detail-head'>Finanace Info</span>
                <Table>
                  <tbody>
                    <tr>
                      <th>Finanace Info&nbsp;:-</th>

                      <td></td>
                    </tr>
                    <tr>
                      <th>Account No&nbsp;:-</th>
                      <td>{editData?.accountNo}</td>
                    </tr>
                    <tr>
                      <th>Account Holder Name&nbsp;:-</th>
                      <td>{editData?.accountHolderName}</td>
                    </tr>
                    <tr>
                      <th>Account Type&nbsp;:-</th>
                      <td>{editData?.accountType}</td>
                    </tr>
                    <tr>
                      <th>Branch&nbsp;:-</th>
                      <td>{editData?.bankName}</td>
                    </tr>
                    <tr>
                      <th>IFSC Code&nbsp;:-</th>
                      <td>{editData?.ifscCode}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
            <div className='col d-flex pv-basicinfo-maintext' id='Other-Info'>
              <div className='w-100 pv-basicinfo-main rounded'>
                <span className='pv-seller-detail-head'>Other Info</span>
                <Table>
                  <tbody>
                    <tr>
                      <th>Shipment By&nbsp;:-</th>

                      <td>{editData?.shipmentBy}</td>
                    </tr>
                    <tr>
                      <th>Shipment Paid By&nbsp;:-</th>
                      <td>{editData?.shipmentChargesPaidByName ?? '-'}</td>
                    </tr>
                    <tr>
                      <th>Allow GST&nbsp;:-</th>
                      <td>{editData?.isUserWithGST ? 'Yes' : 'No'}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div
          className='pv-basicinfo-main pv-basicinfo-maintext justify-content-between rounded'
          id='Kyc-Info'
        >
          <span className='pv-seller-detail-head'>KYC Info</span>
          <div className='row'>
            <div className='col'>
              <Table>
                <tbody>
                  <tr>
                    <th>Pan Card No&nbsp;:-</th>
                    <td>{editData?.panCardNo}</td>
                  </tr>
                  <tr>
                    <th>Name On Pancard&nbsp;:-</th>
                    <td>{editData?.nameOnPanCard}</td>
                  </tr>
                  <tr>
                    <th>Bussiness Type&nbsp;:-</th>
                    <td>{editData?.bussinessType}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <div className='col'>
              <Table>
                <tbody>
                  <tr>
                    <th>MSME No&nbsp;:-</th>
                    <td>{editData?.msmeNo}</td>
                  </tr>
                  <tr>
                    <th>Company Reg No&nbsp;:-</th>
                    <td>{editData?.companyRegitrationNo}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div> */}

        {/* <div
          className='pv-basicinfo-main  pv-basicinfo-maintext  align-items-start justify-content-between rounded'
          id='GST-Info'
        >
          <span className='pv-seller-detail-head mb-2 d-inline-block'>
            GST Info
          </span>
          <div className='row'>
            {editData?.gSTInfos?.length > 0 &&
              editData?.gSTInfos?.map((data) => (
                <div className='col-6'>
                  <Card className='border rounded'>
                    <Card.Body>
                      <Table className='table-borderless'>
                        <tbody>
                          <tr>
                            <th>GST State</th>
                            <td>:</td>
                            <td>{data?.stateName}</td>
                          </tr>
                          <tr>
                            <th>GST No</th>
                            <td>:</td>
                            <td>{data?.gstNo}</td>
                          </tr>
                          <tr>
                            <th>GST Type</th>
                            <td>:</td>
                            <td>{data?.gstType}</td>
                          </tr>
                          <tr>
                            <th>Registered Address</th>
                            <td>:</td>
                            <td>
                              {data?.registeredAddressLine1},{' '}
                              {data?.registeredAddressLine2}
                              {data?.registeredLandmark
                                ? `, ${data?.registeredLandmark}`
                                : ''}
                              , {data?.cityName}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                      <span
                        className='d-flex align-items-center justify-content-end'
                        onClick={() => {
                          downloadURI(
                            data?.gstDoc,
                            `${editData?.fullName}_${data?.gstNo}_GSTDOC`,
                            _gstInfoImg_
                          )
                        }}
                      >
                        <svg
                          role='button'
                          xmlns='http://www.w3.org/2000/svg'
                          width='35'
                          height='35'
                          viewBox='0 0 43 43'
                        >
                          <g
                            id='Group_1998'
                            data-name='Group 1998'
                            transform='translate(-1244 -1679)'
                          >
                            <rect
                              id='Rectangle_801'
                              data-name='Rectangle 801'
                              width='43'
                              height='43'
                              rx='6'
                              transform='translate(1244 1679)'
                              fill='#4067bc'
                            />
                            <path
                              id='Icon_ionic-md-download'
                              data-name='Icon ionic-md-download'
                              d='M23.082,11.416H18.417V4.5h-7v6.916H6.75l8.166,8.069ZM6.75,21.791V24.1H23.082V21.791Z'
                              transform='translate(1250.25 1686.5)'
                              fill='#fff'
                            />
                          </g>
                        </svg>
                      </span>
                    </Card.Body>
                  </Card>
                </div>
              ))}
          </div>
        </div>

        <div
          className='pv-basicinfo-main pv-basicinfo-maintext align-items-center justify-content-between rounded'
          id='Warehouse-Info'
        >
          <span className='pv-seller-detail-head mb-2 d-inline-block'>
            Warehouse Info
          </span>
          <div className='row'>
            {editData?.wareHouses?.length > 0 &&
              editData?.wareHouses?.map((data) => (
                <div className='col-6'>
                  <Card className='border rounded'>
                    <Card.Body>
                      <Table className='table-borderless'>
                        <tbody>
                          <tr>
                            <th>Warehouse Name</th>
                            <td>:</td>
                            <td>{data?.name}</td>
                          </tr>
                          <tr>
                            <th>Name</th>
                            <td>:</td>
                            <td>{data?.companyRegitrationNo}</td>
                          </tr>
                          <tr>
                            <th>Mobile No</th>
                            <td>:</td>
                            <td>{data?.contactPersonMobileNo}</td>
                          </tr>
                          <tr>
                            <th>GST No</th>
                            <td>:</td>
                            <td>{data?.gstNo}</td>
                          </tr>
                          <tr>
                            <th>Address</th>
                            <td>:</td>
                            <td>
                              {data?.addressLine1}, {data?.addressLine1},{' '}
                              {data?.landmark}, {data?.cityName}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>
                </div>
              ))}
          </div>
        </div> */}
        {editData?.brands?.length > 0 && (
          <div
            className='pv-basicinfo-main rounded pv-basicinfo-maintext align-items-center justify-content-between mt-3'
            id='Brand-List'
          >
            <span className='pv-seller-detail-head mb-2 d-inline-block'>
              Brand List
            </span>
            <div className='row'>
              {editData?.brands?.map((obj) => (
                <div
                  className='col-md-4'
                  key={Math.floor(Math.random() * 100000)}
                >
                  {obj?.logo && (
                    <Card className='p-2 d-flex gap-3 align-items-center justify-content-center text-center border rounded'>
                      <Card.Img
                        className='img-fluid'
                        style={{ width: '70%', height: '150px' }}
                        variant='top'
                        src={`${process.env.REACT_APP_IMG_URL}${_brandImg_}${obj?.logo}`}
                      />
                      <Card.Body className='p-0'>
                        <Card.Text>{obj?.brandName}</Card.Text>
                        <span
                          className='d-flex align-items-center justify-content-end'
                          onClick={() => {
                            downloadURI(
                              obj?.logo,
                              `${editData?.fullName}_${obj?.brandName}_brandImg`,
                              _brandImg_
                            )
                          }}
                        >
                          <svg
                            role='button'
                            xmlns='http://www.w3.org/2000/svg'
                            width='35'
                            height='35'
                            viewBox='0 0 43 43'
                          >
                            <g
                              id='Group_1998'
                              data-name='Group 1998'
                              transform='translate(-1244 -1679)'
                            >
                              <rect
                                id='Rectangle_801'
                                data-name='Rectangle 801'
                                width='43'
                                height='43'
                                rx='6'
                                transform='translate(1244 1679)'
                                fill='#0A0A0A'
                              />
                              <path
                                id='Icon_ionic-md-download'
                                data-name='Icon ionic-md-download'
                                d='M23.082,11.416H18.417V4.5h-7v6.916H6.75l8.166,8.069ZM6.75,21.791V24.1H23.082V21.791Z'
                                transform='translate(1250.25 1686.5)'
                                fill='#fff'
                              />
                            </g>
                          </svg>
                        </span>
                      </Card.Body>
                    </Card>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
        {/* <div
          className='pv-basicinfo-main pv-basicinfo-maintext  align-items-center rounded'
          id='Download-Documents'
        >
          <span className='pv-seller-detail-head mb-3 d-inline-block'>
            Download Documents
          </span>
          <div className='row g-3'>
            {editData?.cancelCheque && (
              <div className='col-md-4 pv-download-btn text-center'>
                <button
                  type='button'
                  onClick={() => {
                    downloadURI(
                      editData?.cancelCheque,
                      `${editData?.fullName}_cancelCheque`,
                      _cancelCheaque_
                    )
                  }}
                  className='w-100 rounded'
                  style={{ borderStyle: 'dashed', borderColor: '#aaa' }}
                >
                  <div className='pt-3 pb-3 d-flex flex-column gap-3 align-items-center justify-content-center'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24.123'
                      height='28.948'
                      viewBox='0 0 24.123 28.948'
                    >
                      <path
                        id='Icon_ionic-md-download'
                        data-name='Icon ionic-md-download'
                        d='M30.873,14.715h-6.89V4.5H13.64V14.715H6.75L18.812,26.633ZM6.75,30.041v3.407H30.873V30.041Z'
                        transform='translate(-6.75 -4.5)'
                        fill='#aaa'
                      />
                    </svg>
                    <p>Cancel Cheque</p>
                  </div>
                </button>
                <p className='mt-1'>Cancel Cheque</p>
              </div>
            )}

            {editData?.msmeDoc && (
              <div className='col-md-4 pv-download-btn text-center'>
                <button
                  type='button'
                  onClick={() => {
                    downloadURI(
                      editData?.msmeDoc,
                      editData?.msmeDoc?.split('_')[1],
                      _msmeImg_
                    )
                  }}
                  className='w-100 rounded'
                  style={{ borderStyle: 'dashed', borderColor: '#aaa' }}
                >
                  <div className='pt-3 pb-3 d-flex flex-column gap-3 align-items-center justify-content-center'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24.123'
                      height='28.948'
                      viewBox='0 0 24.123 28.948'
                    >
                      <path
                        id='Icon_ionic-md-download'
                        data-name='Icon ionic-md-download'
                        d='M30.873,14.715h-6.89V4.5H13.64V14.715H6.75L18.812,26.633ZM6.75,30.041v3.407H30.873V30.041Z'
                        transform='translate(-6.75 -4.5)'
                        fill='#aaa'
                      />
                    </svg>
                    <p>MSME Document</p>
                  </div>
                </button>
                <p className='mt-1'>MSME Document</p>
              </div>
            )}

            {editData?.digitalSign && (
              <div className='col-md-4 pv-download-btn text-center'>
                <button
                  type='button'
                  onClick={() => {
                    downloadURI(
                      editData?.digitalSign,
                      editData?.digitalSign?.split('_')[1],
                      _digitalSignImg_
                    )
                  }}
                  className='w-100 rounded'
                  style={{ borderStyle: 'dashed', borderColor: '#aaa' }}
                >
                  <div className='pt-3 pb-3 d-flex flex-column gap-3 align-items-center justify-content-center'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24.123'
                      height='28.948'
                      viewBox='0 0 24.123 28.948'
                    >
                      <path
                        id='Icon_ionic-md-download'
                        data-name='Icon ionic-md-download'
                        d='M30.873,14.715h-6.89V4.5H13.64V14.715H6.75L18.812,26.633ZM6.75,30.041v3.407H30.873V30.041Z'
                        transform='translate(-6.75 -4.5)'
                        fill='#aaa'
                      />
                    </svg>
                    <p>Digital Sign</p>
                  </div>
                </button>
                <p className='mt-1'>Digital Sign</p>
              </div>
            )}

            {editData?.panCardDoc && (
              <div className='col-md-4 pv-download-btn text-center'>
                <button
                  type='button'
                  onClick={() => {
                    downloadURI(
                      editData?.panCardDoc,
                      editData?.panCardDoc?.split('_')[1],
                      _panCardImg_
                    )
                  }}
                  className='w-100 rounded'
                  style={{ borderStyle: 'dashed', borderColor: '#aaa' }}
                >
                  <div className='pt-3 pb-3 d-flex flex-column gap-3 align-items-center justify-content-center'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24.123'
                      height='28.948'
                      viewBox='0 0 24.123 28.948'
                    >
                      <path
                        id='Icon_ionic-md-download'
                        data-name='Icon ionic-md-download'
                        d='M30.873,14.715h-6.89V4.5H13.64V14.715H6.75L18.812,26.633ZM6.75,30.041v3.407H30.873V30.041Z'
                        transform='translate(-6.75 -4.5)'
                        fill='#aaa'
                      />
                    </svg>
                    <p>Pan Card</p>
                  </div>
                </button>
                <p className='mt-1'>Pan Card</p>
              </div>
            )}

            {editData?.aadharCardFrontDoc && (
              <div className='col-md-4 pv-download-btn text-center'>
                <button
                  type='button'
                  onClick={() => {
                    downloadURI(
                      editData?.aadharCardFrontDoc,
                      editData?.aadharCardFrontDoc?.split('_')[1],
                      _adharFrontImg_
                    )
                  }}
                  className='w-100 rounded'
                  style={{ borderStyle: 'dashed', borderColor: '#aaa' }}
                >
                  <div className='pt-3 pb-3 d-flex flex-column gap-3 align-items-center justify-content-center'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24.123'
                      height='28.948'
                      viewBox='0 0 24.123 28.948'
                    >
                      <path
                        id='Icon_ionic-md-download'
                        data-name='Icon ionic-md-download'
                        d='M30.873,14.715h-6.89V4.5H13.64V14.715H6.75L18.812,26.633ZM6.75,30.041v3.407H30.873V30.041Z'
                        transform='translate(-6.75 -4.5)'
                        fill='#aaa'
                      />
                    </svg>
                    <p>Aadhar Card Front</p>
                  </div>
                </button>
                <p className='mt-1'>Aadhar Card Front</p>
              </div>
            )}

            {editData?.aadharCardBackDoc && (
              <div className='col-md-4 pv-download-btn text-center'>
                <button
                  type='button'
                  onClick={() => {
                    downloadURI(
                      editData?.aadharCardBackDoc,
                      editData?.aadharCardBackDoc?.split('_')[1],
                      _adharBackImg_
                    )
                  }}
                  className='w-100 rounded'
                  style={{ borderStyle: 'dashed', borderColor: '#aaa' }}
                >
                  <div className='pt-3 pb-3 d-flex flex-column gap-3 align-items-center justify-content-center'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24.123'
                      height='28.948'
                      viewBox='0 0 24.123 28.948'
                    >
                      <path
                        id='Icon_ionic-md-download'
                        data-name='Icon ionic-md-download'
                        d='M30.873,14.715h-6.89V4.5H13.64V14.715H6.75L18.812,26.633ZM6.75,30.041v3.407H30.873V30.041Z'
                        transform='translate(-6.75 -4.5)'
                        fill='#aaa'
                      />
                    </svg>
                    <p>Aadhar Card Back</p>
                  </div>
                </button>
                <p className='mt-1'>Aadhar Card Back</p>
              </div>
            )}
          </div>
        </div> */}
        {editData?.accountNo &&
          editData?.gSTInfos?.length > 0 &&
          editData?.wareHouses?.length > 0 && (
            <div
              className=' pv-basicinfo-main pv-basicinfo-maintext  align-items-center rounded'
              id='Download-Documents'
            >
              <span className='pv-seller-detail-head mb-2 d-inline-block'>
                Update Details
              </span>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  note: editData?.note ?? '',
                  status: editData?.status ?? ''
                }}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ values, setFieldValue }) => (
                  <Form>
                    <div className='row gap-3 flex-column'>
                      <div className='col-md-12'>
                        <frm.Group>
                          <frm.Label className='mb-1'>Note</frm.Label>
                          <frm.Control
                            as='textarea'
                            rows={2}
                            value={values?.note}
                            onChange={(e) => {
                              setFieldValue('note', e?.target?.value)
                            }}
                          />
                        </frm.Group>
                      </div>
                      <div className='col-md-4'>
                        <p className='mb-1'>Status</p>
                        <Select
                          styles={customStyles}
                          id='status'
                          menuPortalTarget={document.body}
                          placeholder='Select Status'
                          value={
                            values?.status && {
                              label: values?.status,
                              value: values?.status
                            }
                          }
                          options={[
                            {
                              label: 'Pending',
                              value: 'Pending'
                            },
                            {
                              label: 'Approved',
                              value: 'Approved'
                            },
                            {
                              label: 'Not Approved',
                              value: 'Not Approved'
                            }
                          ]}
                          onChange={(e) => {
                            setFieldValue('status', e?.value)
                          }}
                        />
                        <ErrorMessage name='status' component={TextError} />
                      </div>
                    </div>
                    <button
                      type='submit'
                      onSubmit={onSubmit}
                      className='btn btn-secondary btn-ct-lightblue btn-sm mt-3'
                    >
                      Submit Details
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          )}

        <div></div>
        {/* <div
          style={{ visibility: 'hidden' }}
          className='row pv-basicinfo-main pv-basicinfo-maintext  align-items-center justify-content-between'
          id='DOWNLOAD-DOCUMENTS'
        >
          <span className='pv-seller-detail-head'>Download Documents</span>
          <div className='col'>
            <Table>
              <tbody>
                <tr>
                  <th>Pancard</th>
                  <td style={{ textAlign: 'right' }}>
                    <button
                      type='button'
                      className='btn btn-secondary pv-seller-downloadbtn'
                    >
                      Download
                    </button>
                  </td>
                  <td>- MS-PANcard.pdf</td>
                </tr>
                <tr>
                  <th>Cancel Cheque</th>
                  <td style={{ textAlign: 'right' }}>
                    <button
                      type='button'
                      className='btn btn-secondary pv-seller-downloadbtn'
                    >
                      Download
                    </button>
                  </td>
                  <td>- MS-cancel-cheque3.pdf</td>
                </tr>
                <tr>
                  <th>GSTIN</th>
                  <td style={{ textAlign: 'right' }}>
                    <button
                      type='button'
                      className='btn btn-secondary pv-seller-downloadbtn'
                    >
                      Download
                    </button>
                  </td>
                  <td>- MS-GSTIN-certificate.pdf</td>
                </tr>
                <tr>
                  <th>Brand Certificate</th>
                  <td style={{ textAlign: 'right' }}>
                    <button
                      type='button'
                      className='btn btn-secondary pv-seller-downloadbtn'
                    >
                      Download
                    </button>
                  </td>
                  <td>- MS-brand-certificate2.pdf</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
        <div
          style={{ visibility: 'hidden' }}
          className='row pv-basicinfo-main pv-basicinfo-maintext  align-items-center justify-content-between'
          id='DOWNLOAD-DOCUMENTS'
        >
          <span className='pv-seller-detail-head'>Download Documents</span>
          <div className='col'>
            <Table>
              <tbody>
                <tr>
                  <th>Pancard</th>
                  <td style={{ textAlign: 'right' }}>
                    <button
                      type='button'
                      className='btn btn-secondary pv-seller-downloadbtn'
                    >
                      Download
                    </button>
                  </td>
                  <td>- MS-PANcard.pdf</td>
                </tr>
                <tr>
                  <th>Cancel Cheque</th>
                  <td style={{ textAlign: 'right' }}>
                    <button
                      type='button'
                      className='btn btn-secondary pv-seller-downloadbtn'
                    >
                      Download
                    </button>
                  </td>
                  <td>- MS-cancel-cheque3.pdf</td>
                </tr>
                <tr>
                  <th>GSTIN</th>
                  <td style={{ textAlign: 'right' }}>
                    <button
                      type='button'
                      className='btn btn-secondary pv-seller-downloadbtn'
                    >
                      Download
                    </button>
                  </td>
                  <td>- MS-GSTIN-certificate.pdf</td>
                </tr>
                <tr>
                  <th>Brand Certificate</th>
                  <td style={{ textAlign: 'right' }}>
                    <button
                      type='button'
                      className='btn btn-secondary pv-seller-downloadbtn'
                    >
                      Download
                    </button>
                  </td>
                  <td>- MS-brand-certificate2.pdf</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default SellerDetails
