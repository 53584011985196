import React, { useState, useEffect } from 'react'
import { Table } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import SearchBox from '../../../components/Searchbox'
import useDebounce from '../../../lib/useDebounce'
import axiosProvider from '../../../lib/AxiosProvider'
import Loader from '../../../components/Loader'
import { customStyles } from '../../../components/customStyles'
import { _SwalDelete } from '../../../lib/exceptionMessage'
import { _status_, pageRangeDisplayed } from '../../../lib/GetBaseUrl'
import { _brandImg_ } from '../../../lib/ImagePath'
import { _alphabetRegex_ } from '../../../lib/Regex'
import { useImmer } from 'use-immer'

const MainUser = () => {
  const [searchText, setSearchText] = useState('')
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)
  const [filterDetails, setFilterDetails] = useImmer({
    pageSize: 25,
    pageIndex: 1,
    searchText: ''
  })

  const debounceSearchText = useDebounce(searchText, 500)

  useEffect(() => {
    if (debounceSearchText) {
      setFilterDetails((draft) => {
        draft.searchText = debounceSearchText
        draft.pageIndex = 1
      })
    } else {
      setFilterDetails((draft) => {
        draft.searchText = ''
        draft.pageIndex = 1
      })
    }
  }, [debounceSearchText])

  const fetchData = async () => {
    setLoading(true)
    const response = await axiosProvider({
      method: 'GET',
      endpoint: 'CustomerData/search',
      queryString: `?searchText=${filterDetails?.searchText}&pageIndex=${filterDetails?.pageIndex}&pageSize=${filterDetails?.pageSize}`
    })
      .then((res) => {
        setLoading(false)
        if (res?.status === 200) {
          setData(res)
        }
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchData()
  }, [filterDetails])

  const handlePageClick = (event) => {
    setFilterDetails((draft) => {
      draft.pageIndex = event.selected + 1
    })
  }

  return (
    <>
      <div className='d-flex align-items-center mb-3 gap-3'>
        <div className='d-flex align-items-center'>
          <label className='me-1'>Show</label>
          <select
            styles={customStyles}
            name='dataget'
            id='parpageentries'
            className='form-select me-1'
            value={filterDetails?.pageSize}
            onChange={(e) => {
              setFilterDetails((draft) => {
                draft.pageSize = e?.target?.value
                draft.pageIndex = 1
              })
            }}
          >
            <option value='10'>10</option>
            <option value='25'>25</option>
            <option value='50'>50</option>
            <option value='100'>100</option>
            <option value='200'>200</option>
            <option value='500'>500</option>
          </select>
        </div>
        <SearchBox
          placeholderText={'Search'}
          value={searchText}
          searchclassnamewrapper={'searchbox-wrapper'}
          onChange={(e) => {
            setSearchText(e?.target?.value)
          }}
        />
      </div>

      {loading && <Loader />}

      <Table responsive className='align-middle table-list'>
        <thead>
          <tr>
            <th>User Name</th>
            <th>Email Id</th>
            <th>Profession</th>
            <th>Gender</th>
            <th>Mobile Number</th>
            <th>City</th>
            <th>Pincode</th>
          </tr>
        </thead>
        <tbody>
          {data?.data?.data?.length > 0 ? (
            data?.data?.data?.map((data, index) => (
              <tr key={`${data.id}-${index}`}>
                <td>
                  {data?.firstName} {data?.lastName}
                </td>
                <td>{data.userName}</td>
                <td>{data.profession}</td>
                <td>{data.gender}</td>
                <td>{data.mobileNo}</td>
                <td>{data.city}</td>
                <td>{data.pincode}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4} className='text-center'>
                {data?.data?.message}
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <ReactPaginate
        className='list-inline m-cst--pagination d-flex justify-content-end gap-1'
        breakLabel='...'
        nextLabel=''
        onPageChange={handlePageClick}
        pageRangeDisplayed={pageRangeDisplayed}
        pageCount={data?.data?.pagination?.pageCount ?? 0}
        previousLabel=''
        renderOnZeroPageCount={null}
        forcePage={filterDetails?.pageIndex - 1}
      />
    </>
  )
}

export default MainUser
